<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main" shadow="never">
            <el-tabs v-model="tabAc" >
                <el-tab-pane label="时长计费模式" name="jcdj" :disabled="priceId?true:false">
                    <div class="a-mt-8 a-mtb-10" v-if="priceId">
                        <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                    </div>
                    <le-price-edit :priceId="priceId" @cancel="handlerCancel" @submit="handlerSubmit" ></le-price-edit>
                </el-tab-pane>
                <el-tab-pane label="电量计费模式" name="fwdj" :disabled="priceId?true:false">
                    <div class="a-mt-8 a-mtb-10" v-if="priceId">
                        <span class="a-c-orange">共有{{stationSize}}个站点正在使用该定价</span>
                    </div>
                    <price-serve-edit :priceId="priceId" @cancel="handlerCancel" @submit="handlerSubmit"></price-serve-edit>
                </el-tab-pane>
            </el-tabs>
		</el-card>
	</div>
</template>

<script>
	import util from "../../utils/util.js";
	import LePriceEdit from '../components/page/le-price-edit.vue'
    import PriceServeEdit from './components/price-serve-edit.vue';
	export default {
		components:{
			'le-price-edit':LePriceEdit,
            PriceServeEdit,
		},
		data() {
			return {
				util: util,
                type: '',
				priceId:'',
				stationSize:0,
                tabAc: 'jcdj',
                timeBaseForm: {
                    name: '',
                    description: '',
                    baseAmount: '',
                    baseMinutes: '',
                    stepAmount: '',
                    stepMinutes: '',
                    hesitationMinutes: '',
                    maxMinutes: ''
                },
                timePowerForm: {
                    openPower: false,
                    powerDetail: [],
                }
			}
		},
        created () {
			this.type = parseInt(this.$route.query.type)
            if(this.type == 0 || this.type == 1 || this.type == 3 || this.type == 4 || this.type == 6 || this.type == 8 || !this.type) {
                this.tabAc = 'jcdj'
            }else{
                this.tabAc = 'fwdj'
            }
        },
		mounted() {
			this.priceId = parseInt(this.$route.query.priceId)
			if(this.priceId){
				this.getPlanUserStationSize();
			}
		},
		methods: {
			/**
			 * 获取定价相关的站点数
			 */
			getPlanUserStationSize() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanUserStationSize,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
					this.stationSize = data;
				})
			},
			
			handlerCancel(){
				this.$router.back()
                this.$store.dispatch("delView", this.$route);
			},
			
			handlerSubmit(){
				this.$store.dispatch("remove_current_view", this).then(()=>{
					this.$router.push({
						path: '/price/price-list',
						
					})
				})
				
			}
			
		}
	}
</script>

<style scoped lang="scss">
/deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
    color: #007AFF;
    font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
    background: none !important;
}
</style>
