<template>
    <div style="max-width:1200px">
        <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="定价名称" prop="name" >
                        <el-input v-model="form.name" maxlength="24"  placeholder="请输入定价名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="定价描述" prop="description" >
                        <el-input v-model="form.description" maxlength="50"  placeholder="请输入定价描述"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="电费" prop="baseAmount" >
                        <div class="a-flex-rfsc">
                            <el-input v-model="form.baseAmount" maxlength="10" @input="form.baseAmount = util.checkMoney5(form.baseAmount)" placeholder="请输入费用">
                                <template slot="append">元/kwh</template>
                            </el-input>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="犹豫时长" prop="hesitationMinutes" >
                        <el-input v-model="form.hesitationMinutes" maxlength="10"  placeholder="请输入犹豫时长">
                            <template slot="append">分钟</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item 
                        label="起步时长" 
                        prop="baseMinutes" 
                        v-if="!form.isService">
                        <el-input type="number" placeholder="请输入起步时长" v-model="form.baseMinutes">
                            <template slot="append">分钟</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item 
                        label="最长时长" 
                        prop="maxMinutes" >
                        <el-input type="number" placeholder="请输入最长时长" v-model="form.maxMinutes">
                            <template slot="append">分钟</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12" v-if="form.chooseShowType != 1">
                    <el-form-item :label="form.powerType==2 || form.chooseShowType == 3?'默认开电金额':'默认开电时长'" prop="defaultChoicePrice" >
                        <el-input v-model="form.defaultChoicePrice" maxlength="10"  placeholder="请输入">
                            <template slot="append">{{ form.powerType==2 || form.chooseShowType == 3?'元':'分钟' }}</template>
                        </el-input>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <div class="a-mt-45 a-flex-rfsc" >
                <span class="a-fs-16 a-c-333 a-fw-b">电费服务费</span>
                <le-switch v-model="form.isService" inacColor="#bbb"></le-switch>
            </div>
            <div class="a-flex-rfsc a-mtb-12" v-if="form.isService==1">
                <el-radio-group v-model="form.serviceType" @change='serviceTypeChange'>
                    <el-radio :label="1">按时长</el-radio>
                    <el-radio :label="2">按电量</el-radio>
                </el-radio-group>
            </div>
            <el-row :gutter="24">
                <el-col :span="24" v-if="form.isService && form.serviceType == 2">
                    <el-form-item label="基础服务费" prop="chargeBaseFee" >
                        <div class="a-flex-rfsc">
                            <el-input style="width: 300px" v-model="form.chargeBaseFee" maxlength="10" @input="form.chargeBaseFee = util.checkMoney5(form.chargeBaseFee)" placeholder="请输入费用" >
                                <template slot="append">元/kwh</template>
                            </el-input>
                        </div>
                    </el-form-item> 
                    <div class="a-flex-rfsc a-mtb-12">
                        <el-radio-group v-model="form.chooseShowType" @change='chooseShowTypeChange'>
                            <el-radio :label="1">按电量展示</el-radio>
                            <el-radio :label="2">按时长展示</el-radio>
                            <el-radio :label="3">按金额展示</el-radio>
                        </el-radio-group>
                    </div>
                    <el-form-item label="" label-width='0' prop="chooseShowType" >
                        <div  v-if="form.chooseShowType == 1">
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最低电量选项</span>
                                <el-input  v-model="form.lowChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入起步电量" >
                                    <template slot="append">kwh</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最高电量选项</span>
                                <el-input  v-model="form.highChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入最高电量" >
                                    <template slot="append">kwh</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">步长间隔</span>
                                <el-input  v-model="form.stepChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入电量步长" >
                                    <template slot="append">kwh</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">默认开电电量</span>
                                <el-input  v-model="form.defaultChoicePrice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入默认开电电量" >
                                    <template slot="append">kwh</template>
                                </el-input>
                            </div>
                        </div>
                        <div class="" v-if="form.chooseShowType == 2">
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最低时长选项</span>
                                <el-input  v-model="form.lowChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入起步时长" >
                                    <template slot="append">分钟</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最高时长选项</span>
                                <el-input  v-model="form.highChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入最高时长" >
                                    <template slot="append">分钟</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">步长间隔</span>
                                <el-input  v-model="form.stepChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入时长步长" >
                                    <template slot="append">分钟</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">默认开电时长</span>
                                <el-input  v-model="form.defaultChoicePrice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入默认开电时长" >
                                    <template slot="append">分钟</template>
                                </el-input>
                            </div>
                        </div>
                        <div class="" v-if="form.chooseShowType == 3">
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最低金额选项</span>
                                <el-input  v-model="form.lowChoice" maxlength="50" @input="form.lowChoice = util.checkMoney(form.lowChoice)" style="width:200px;padding-left:10px" placeholder="请输入起步金额" >
                                    <template slot="append">元</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">最高金额选项</span>
                                <el-input  v-model="form.highChoice" maxlength="50" @input="form.highChoice = util.checkMoney(form.highChoice)" style="width:200px;padding-left:10px" placeholder="请输入最高金额" >
                                    <template slot="append">元</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">步长间隔</span>
                                <el-input  v-model="form.stepChoice" maxlength="50" @input="form.stepChoice = util.checkMoney(form.stepChoice)" style="width:200px;padding-left:10px" placeholder="请输入金额步长" >
                                    <template slot="append">元</template>
                                </el-input>
                            </div>
                            <div class="a-flex-rfsc a-mt-10">
                                <span class="a-fs-14">默认开电金额</span>
                                <el-input  v-model="form.defaultChoicePrice" maxlength="50" @input="form.defaultChoicePrice = util.checkMoney(form.defaultChoicePrice)" style="width:200px;padding-left:10px" placeholder="请输入默认开电金额" >
                                    <template slot="append">元</template>
                                </el-input>
                            </div>
                            
                        </div>
                    </el-form-item>
                    <el-form-item label="" label-width='0' prop="baseChoice" >
                        <div class="a-flex-rfsc a-mt-15">
                            <span class="a-fs-14 a-fw-700">收费规则：</span>
                            <span class="a-fs-14">收费起步电量</span>
                            <el-input v-model="form.baseChoice" maxlength="8" style="width:200px;padding-left:10px" @input='handlerInputBaseChoice' placeholder="请输入起步电量" >
                                <template slot="append">kwh</template>
                            </el-input>
                            <span class="a-fs-14 a-plr-10">，超出起步电量按</span>
                            <el-input  v-model="form.overChoice" :disabled='!Number(form.baseChoice)' maxlength="8" style="width:200px" placeholder="请输入超出电量" >
                                <template slot="append">kwh</template>
                            </el-input>
                        </div>
                    </el-form-item>
                    
                </el-col>
            </el-row>
            <le-price-service :form.sync="serviceInfo" :showSpecial='false' v-if="form.isService && form.serviceType == 1"></le-price-service>
            <div class="a-flex-rfsc a-mtb-12" v-if="form.isService && form.serviceType == 1">
                <el-radio-group v-model="form.powerType" @change='powerTypeChange'>
                    <el-radio :label="0">基础电费服务费</el-radio>
                    <el-radio :label="1" :disabled='!serviceInfo.serviceBaseFee || !serviceInfo.serviceBaseTime'>功率电费服务费按时长</el-radio>
                    <el-radio :label="2" :disabled='!serviceInfo.serviceBaseFee || !serviceInfo.serviceBaseTime'>功率电费服务费按金额</el-radio>
                </el-radio-group>
            </div>
            <el-form-item label="" label-width='0' prop="chooseShowType" v-if="form.isService && form.serviceType == 1">
                <div class="a-flex-cfsfs" v-if="form.powerType == 1 || form.powerType == 0">
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">最低时长选项</span>
                        <el-input  v-model="form.lowChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入起步时长" >
                            <template slot="append">分钟</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">最高时长选项</span>
                        <el-input  v-model="form.highChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入最高时长" >
                            <template slot="append">分钟</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">步长间隔</span>
                        <el-input  v-model="form.stepChoice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入时长步长" >
                            <template slot="append">分钟</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">默认开电时长</span>
                        <el-input  v-model="form.defaultChoicePrice" maxlength="8" style="width:200px;padding-left:10px" placeholder="请输入默认开电时长" >
                            <template slot="append">分钟</template>
                        </el-input>
                    </div>
                </div>
                <div class="a-flex-cfsfs" v-if="form.powerType == 2">
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">最低金额选项</span>
                        <el-input  v-model="form.lowChoice" maxlength="50" @input="form.lowChoice = util.checkMoney(form.lowChoice)" style="width:200px;padding-left:10px" placeholder="请输入起步金额" >
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">最高金额选项</span>
                        <el-input  v-model="form.highChoice" maxlength="50" @input="form.highChoice = util.checkMoney(form.highChoice)" style="width:200px;padding-left:10px" placeholder="请输入最高金额" >
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">步长间隔</span>
                        <el-input  v-model="form.stepChoice" maxlength="50" @input="form.stepChoice = util.checkMoney(form.stepChoice)" style="width:200px;padding-left:10px" placeholder="请输入金额步长" >
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span class="a-fs-14">默认开电金额</span>
                        <el-input  v-model="form.defaultChoicePrice" maxlength="50" @input="form.defaultChoicePrice = util.checkMoney(form.defaultChoicePrice)" style="width:200px;padding-left:10px" placeholder="请输入默认开电金额" >
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                </div>
            </el-form-item>
 
            <div class="a-flex-rfsc" v-if="form.serviceType == 1 && form.powerType == 1">
                <span class="a-fs-14 a-c-333">自选功率</span>
                <le-switch v-model="form.selfPower" inacColor="#bbb"></le-switch>
            </div>
            <span class="a-mt-16 a-c-red a-fs-14" v-if="form.serviceType == 1">* 选择功率电费计费前，请先完成基础电费服务费定价设置</span> 
            <el-table :data="servicePowerDetail" :highlight-current-row="true" class="a-mt-24" v-if="form.powerType && form.serviceType == 1">
                <el-table-column label="是否默认" v-if="form.selfPower" width='80'>
                    <template slot-scope="scope">
                        <el-radio v-model="scope.row.isDefault" :label="1" @input="handleSetDefaultPower(scope.$index)">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="功率范围（瓦）" width="300">
                    <template slot-scope="scope">
                        <div class="a-ptb-18 a-flex-rfsc">
                            <el-input type="number" style="width: 96px;" v-model="scope.row.minPower"
                                @input="e=>handlerMinPowerInput(e,scope.$index)"
                                @blur="e=>handlerMinPowerBlur(e,scope.$index)">
                            </el-input>
                            <span class="a-fs-14 a-c-666 a-ml-12">-</span>
                            <el-input type="number" class="a-ml-12 " style="width: 96px;" v-model="scope.row.maxPower"
                                @input="e=>handlerMaxPowerInput(e,scope.$index)"
                                @blur="e=>handlerMaxPowerBlur(e,scope.$index)">
                            </el-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="基础服务费" >
                    <template slot-scope="scope">
                        <div class="a-ptb-18 a-flex-rfsc" v-if="form.powerType == 1">
                            <el-input type="number" style="width: 120px;" v-model="scope.row.serviceBaseFee"
                                @input="e=>handlerScaleAmountInput(e,scope.$index)">
                            </el-input>
                            <span class="a-fs-14 a-c-666 a-ml-12">元</span> 
                            <span class="a-fs-14 a-c-333 a-ml-12">{{scope.row.serviceBaseTime}}</span>
                            <span class="a-fs-14 a-c-666 a-ml-12">分钟</span>
                        </div>
                        <div class="a-ptb-18 a-flex-rfsc" v-else>
                            <span>{{ scope.row.serviceBaseFee }}</span>
                            <span class="a-fs-14 a-c-666 a-ml-12">元</span> 
                            <el-input type="number" style="width: 96px;margin-left:10px" v-model="scope.row.serviceBaseTime"
                                @input="e=>handlerScaleMinutesInput(e,scope.$index)">
                            </el-input>
                            <span class="a-fs-14 a-c-666 a-ml-12">分钟</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="a-flex-rfsc">
                            <div @click="handlerAddDetailItem(scope.$index)" class="a-cursor-p">
                                <el-tooltip class="item" effect="dark" content="添加条目" placement="top">
                                    <img src="../../../assets/icon/option-add-circle.png" class="a-wh-16" />
                                </el-tooltip>
                            </div>
                            <div @click="handlerDelDetailItem(scope.$index)" class="a-cursor-p a-ml-16"
                                v-if="scope.$index>0">
                                <el-tooltip class="item" effect="dark" content="删除条目" placement="top">
                                    <img src="../../../assets/icon/option-del-circle.png" class="a-wh-16" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="a-mt-45 a-flex-rfsc" >
                <span class="a-fs-16 a-c-333 a-fw-b">占位服务费</span>
                <le-switch v-model="form.isOccupation" inacColor="#bbb"></le-switch>
            </div>
            <le-price-service :form.sync="occupationInfo" :showSpecial='true' v-if="form.isOccupation"></le-price-service>
            <div class="a-mt-45 a-flex-rfsc">
                <span class="a-fs-16 a-c-333 a-fw-b">管理费</span>
                <le-switch v-model="form.needManagermentFee" :active-value="1" :inactive-value="0" inacColor="#bbb"></le-switch>
            </div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="a-flex-rfsc" v-if="form.needManagermentFee">
                        <el-input v-model="form.managermentFee" maxlength="50" @input="form.managermentFee = util.checkMoney(form.managermentFee)" placeholder="请输入费用" class="input-size"></el-input>
                        <span class="form-text">元</span>
                        <el-select v-model="form.managermentMinutes" clearable placeholder="时长" class="input-size">
                            <el-option
                                v-for="(ite,idx) in managermentMinutesDic"
                                :key="idx"
                                :label="ite.label"
                                :value="ite.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            <div class="a-mt-45 a-flex-rfsc" v-if="form.needManagermentFee || form.isOccupation">
                <span class="a-fs-16 a-c-333 a-fw-b">服务费/管理费付费模式</span>
                <el-radio-group v-model="form.postServiceFee" class="a-ml-24">
                    <el-radio :label="0">现付</el-radio>
                    <el-radio :label="1">后付</el-radio>
                </el-radio-group>
            </div>
            <!-- <el-row :gutter="20" v-if="form.needManagermentFee || form.isService">
                <el-col :span="20">
                    <el-form-item class="a-mt-20" label="服务费/管理费付费模式" prop="postServiceFee" label-width='200px'>
                        <el-radio-group v-model="form.postServiceFee">
                            <el-radio :label="0">现付</el-radio>
                            <el-radio :label="1">后付</el-radio>
                        </el-radio-group>
                    </el-form-item> 
                </el-col>
            </el-row> -->
           
        </el-form>
        <div style="height:100px"></div>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
            <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">返回</el-button>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util'
import lePriceService from '../../components/page/le-price-service.vue';
export default {
    components: {
        lePriceService
    },
    data() {
        var checkServiceBaseFee = (rule, value, callback) => {
            if(!this.form.chargeBaseFee && this.form.chargeBaseFee!==0 && this.form.chargeBaseFee!=='0'){
                callback(new Error('请输入基础电费服务费'))
            }else if(!this.form.serviceBaseTime && this.form.serviceType == 1){
                callback(new Error('请输入基础电费服务费计时时长'))
            }else{
                callback()
            }
        };
        var checkStepAmount = (rule, value, callback) => {
            if(!this.form.stepAmount){
                callback(new Error('请输入加时费用'))
            }else if(!this.form.stepMinutes){
                callback(new Error('请输入加时时长'))
            }else{
                callback()
            }
        };
        var checkBaseAmount = (rule, value, callback) => {
            if(this.form.type == 2 || this.form.type == 5){
                if(!this.form.baseAmount){
                    callback(new Error('请输入电费'))
                }else{
                    callback()
                }
            }else{
                if(!this.form.baseAmount){
                    callback(new Error('请输入起步费用'))
                }else if(!this.form.baseMinutes){
                    callback(new Error('请输入起步时长'))
                }else{
                    callback()
                }
            }
        };
        var checkManagermentFee = (rule, value, callback) => {
            if(this.form.needManagermentFee){
                if(!this.form.managermentFee){
                    callback(new Error('请输入管理费'))
                }else if(!this.form.managermentMinutes){
                    callback(new Error('请选择管理费时长'))
                }else{
                    callback()
                }
            }else{
                callback()
            }
        };
        var checkChooseShowType = (rule, value, callback) => {
            if(!this.form.lowChoice) {
                callback(new Error('请输入最低选项'))
            }else if(!this.form.highChoice) {
                callback(new Error('请输入最高选项'))
            }else if(!this.form.stepChoice) {
                callback(new Error('请输入步长间隔'))
            }else {
                callback()
            }
        };
        var checkBaseChoice = (rule, value, callback) => {
            if(!this.form.baseChoice) {
                callback(new Error('请输入起步电量'))
            }else if(!this.form.overChoice) {
                callback(new Error('请输入超出电量步长'))
            }else {
                callback()
            }
        };
        return {
            util: util,
            id: '',
            form:{
                name: '',
                description: '',
                baseAmount: '',
                baseMinutes: 240,
                maxMinutes: 720,
                stepAmount: '',
                stepMinutes: 60,
                lowChoice: 240,
                highChoice: 720,
                stepChoice: 60,
                baseChoice: '',
                overChoice: '',
                hesitationMinutes: 0,
                serviceBaseFee: '',
                serviceBaseTime: '',
                chargeBaseFee: '',
                rule: [],
                type: 2,
                chargingDelayTime: '',
                managermentFee: '',
                needManagermentFee: 0,
                managermentMinutes: '',
                isService: 0,
                isServicePower: 0,
                serviceType: 1,
                selfPower: 0,
                chooseShowType: 2,
                postServiceFee: 0,
                defaultChoicePrice: 480,
                powerType: 0,
                isOccupation: 0,//是否开启占位费
            },
            serviceInfo: {
                serviceBaseFee: '',
                serviceBaseTime: '',
                chargingDelayTime: '',
                rule: [],
            },//服务费信息
            occupationInfo: {
                serviceBaseFee: '',
                serviceBaseTime: '',
                chargingDelayTime: '',
                rule: [],
            },//占位费信息
            servicePowerDetail: [],//功率服务费信息
            servicePowerDetailInit: [{
                minPower: 0,
                maxPower: 200,
                chargeScale: 100,
                serviceBaseFee: 0,
                serviceBaseTime: 0,
                isDefault: 0
            },
            {
                minPower: 201,
                maxPower: 400,
                chargeScale: 125,
                serviceBaseFee: 0,
                serviceBaseTime: 0,
                isDefault: 1
            },
            {
                minPower: 401,
                maxPower: 600,
                chargeScale: 150,
                serviceBaseFee: 0,
                serviceBaseTime: 0,
                isDefault: 0
            },
            {
                minPower: 601,
                maxPower: 800,
                chargeScale: 175,
                serviceBaseFee: 0,
                serviceBaseTime: 0,
                isDefault: 0
            },
            {
                minPower: 801,
                maxPower: 1200,
                chargeScale: 200,
                serviceBaseFee: 0,
                serviceBaseTime: 0,
                isDefault: 0
            }],
            rules:{
                name: [{required: true, message:'请输入定价名称', trigger: 'blur'}],
                description: [{required: true, message:'请输入定价描述', trigger: 'blur'}],
                baseAmount: [{ validator: checkBaseAmount, trigger: 'blur' }],
                stepAmount: [{ validator: checkStepAmount, trigger: 'blur' }],
                hesitationMinutes: [{required: true, message:'请输入犹豫时长', trigger: 'blur'}],
                serviceBaseFee: [{ validator: checkServiceBaseFee, trigger: 'blur' }],
                managermentFee: [{ validator: checkManagermentFee, trigger: 'blur' }],
                chooseShowType: [{ validator: checkChooseShowType, trigger: 'change' }],
            },
            options: [],
            feeRuleTypeDic: [],//生效类型字典
            managermentMinutesDic: [{
                label: '1分钟',
                value: 1
            },{
                label: '5分钟',
                value: 5
            },{
                label: '10分钟',
                value: 10
            },{
                label: '15分钟',
                value: 15
            },{
                label: '20分钟',
                value: 20
            },{
                label: '30分钟',
                value: 30
            }]
        };
    },
    props:{
        priceId: {
            default: ''
        }
    },
    mounted() {
        this.options = []
        for (let index = 0; index < 24; index++) {
            if(index<10){
                this.options.push('0' + index + ':00')
            }else{
                this.options.push(index + ':00')
            }
        }
        this.$getDic('feeRuleType','select').then(res=>{ this.feeRuleTypeDic = res; })
    },
    watch:{
        priceId: {
            handler (val) {
                this.id = val
                if(this.id){
                    this.getPlanInfo()
                }
            }
        },
        'occupationInfo.serviceBaseTime':{
            handler (val) {
                this.occupationInfo.rule.map(item=>{
                    item.serviceMinutes = val
                })
            }
        },
        'form.isService': {
            handler (val) {
                if(!val) {
                    this.form.isServicePower = 0
                }
            }
        },
        // 'form.isServicePower':{
        //     handler () {
        //         this.servicePowerDetail = JSON.parse(JSON.stringify(this.servicePowerDetailInit))

        //         // this.form.defaultChoicePrice = '' 
        //         if(this.form.powerType == 1) {
        //             this.servicePowerDetail.map((item,index) => {
        //                 if(index == 0) {
        //                     item.serviceBaseFee = this.serviceInfo.serviceBaseFee
        //                 }else {
        //                     item.serviceBaseFee = parseInt((this.serviceInfo.serviceBaseFee * item.chargeScale / 100)*100)/100
        //                 }
        //                 item.serviceBaseTime = this.serviceInfo.serviceBaseTime
        //             })
        //         }else if(this.form.powerType == 2) {
        //             this.servicePowerDetail.map((item,index) => {
        //                 if(index == 0) {
        //                     item.serviceBaseTime = this.serviceInfo.serviceBaseTime
        //                 }else {
        //                     item.serviceBaseTime = (this.serviceInfo.serviceBaseTime / item.chargeScale * 100).toFixed()
        //                 }
        //                 item.serviceBaseFee = this.serviceInfo.serviceBaseFee
        //             })
        //         }
        //         this.handlerOpenServicePower()
        //     }
        // },
        'serviceInfo.serviceBaseFee': {
            handler (val) {
                if(val) {
                    this.servicePowerDetail = JSON.parse(JSON.stringify(this.servicePowerDetailInit))
                    // this.form.defaultChoicePrice = '' 
                    if(this.form.powerType == 1) {
                        this.servicePowerDetail.map((item,index) => {
                            if(index == 0) {
                                item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                            }else {
                                item.serviceBaseFee = parseInt((this.serviceInfo.serviceBaseFee * item.chargeScale / 100)*100)/100
                            }
                            item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                        })
                    }else if(this.form.powerType == 2) {
                        this.servicePowerDetail.map((item,index) => {
                            if(index == 0) {
                                item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                            }else {
                                item.serviceBaseTime = (this.serviceInfo.serviceBaseTime / item.chargeScale * 100).toFixed()
                            }
                            item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                        })
                    }
                }
            }
        },
        'serviceInfo.serviceBaseTime': {
            handler (val) {
                if(val) {
                    this.servicePowerDetail = JSON.parse(JSON.stringify(this.servicePowerDetailInit))
                    
                    // this.form.defaultChoicePrice = '' 
                    if(this.form.powerType == 1) {
                        this.servicePowerDetail.map((item,index) => {
                            if(index == 0) {
                                item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                            }else {
                                item.serviceBaseFee = parseInt((this.serviceInfo.serviceBaseFee * item.chargeScale / 100)*100)/100
                            }
                            item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                        })
                    }else if(this.form.powerType == 2) {
                        this.servicePowerDetail.map((item,index) => {
                            if(index == 0) {
                                item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                            }else {
                                item.serviceBaseTime = (this.serviceInfo.serviceBaseTime / item.chargeScale * 100).toFixed()
                            }
                            item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                        })
                    }
                }
            }
        }
    },
    methods: {
        addServerItem () {
            this.form.rule.push({
                startTime: '',
                endTime: '',
                serviceFee: '',
                serviceMinutes: this.form.serviceBaseTime,
                feeRuleType: ''
            })
        },
        deleteServiceItem (index) {
            this.form.rule.splice(index,1)
        },

        handlerMinPowerInput(e, index) {
            if (index > 0 && e !== '') {
                this.servicePowerDetail[index - 1].maxPower = parseInt(e) - 1
            }
        },
        handlerMinPowerBlur(e, index) {
            if (index > 0 && e.target.value !== '') {
                this.servicePowerDetail[index - 1].maxPower = parseInt(e.target.value) - 1
                if (this.servicePowerDetail[index - 1].maxPower - this.servicePowerDetail[index - 1].minPower <= 0) {
                    this.servicePowerDetail.splice(index - 1, 1)
                }
            }
        },
        handlerMaxPowerInput(e, index) {
            if (index != this.servicePowerDetail.length - 1 && e !== '') {
                this.servicePowerDetail[index + 1].minPower = parseInt(e) + 1

            }
        },
        handlerMaxPowerBlur(e, index) {
            if (index != this.servicePowerDetail.length - 1 && e.target.value !== '') {
                this.servicePowerDetail[index + 1].minPower = parseInt(e.target.value) + 1
                if (this.servicePowerDetail[index + 1].maxPower - this.servicePowerDetail[index + 1].minPower <= 0) {
                    this.servicePowerDetail.splice(index + 1, 1)
                }
            }
        },
        handlerScaleAmountInput(e, index) {
            let amount = e;
            if ((amount + '').match(/.*\.\d{3,10}/)) {
                this.$message.error("价格只能精确到分")
                this.servicePowerDetail[index].serviceBaseFee = util.checkMoney(this.servicePowerDetail[index].serviceBaseFee)
            }
            if (amount) {
                this.servicePowerDetail[index].chargeScale = (this.servicePowerDetail[index].serviceBaseFee / this.serviceInfo.serviceBaseFee *
                    100).toFixed(5)
            }
        },
        handlerScaleMinutesInput(e, index) {
            let minutes  = e;
            if(minutes && !(minutes+'').match(/\d{1,10}/)){
                this.$msg("时长只能是整数")
                this.servicePowerDetail[index].serviceBaseTime = parseFloat(minutes).toFixed()
                return;
            }
            if(minutes){
                this.servicePowerDetail[index].chargeScale = (this.servicePowerDetail[index].serviceBaseTime / this.serviceInfo.serviceBaseTime *
                    100).toFixed(5)
            }
        },
        handlerAddDetailItem(index) {
            let lastDetail = this.servicePowerDetail[index]
            if(this.form.powerType == 1) {
                this.servicePowerDetail.splice(index + 1, 0, {
                    minPower: Number(lastDetail.maxPower) + 1,
                    maxPower: Number(lastDetail.maxPower) + 200,
                    chargeScale: Number(lastDetail.chargeScale) + 25,
                    serviceBaseFee: ((Number(lastDetail.chargeScale) + 25) * this.serviceInfo.serviceBaseFee / 100).toFixed(2),
                    serviceBaseTime: this.serviceInfo.serviceBaseTime
                })
            }else {
                this.servicePowerDetail.splice(index + 1, 0, {
                    minPower: Number(lastDetail.maxPower) + 1,
                    maxPower: Number(lastDetail.maxPower) + 200,
                    chargeScale: Number(lastDetail.chargeScale) + 25,
                    serviceBaseFee: this.serviceInfo.serviceBaseFee,
                    serviceBaseTime: (this.serviceInfo.serviceBaseTime / (Number(lastDetail.chargeScale) + 25) * 100).toFixed(0)
                })
            }
            

            for (let i = 1; i < this.servicePowerDetail.length; i++) {
                if (this.servicePowerDetail[i].minPower != parseInt(this.servicePowerDetail[i - 1].maxPower) + 1) {
                    this.servicePowerDetail[i].minPower = parseInt(this.servicePowerDetail[i - 1].maxPower) + 1
                }
                if (this.servicePowerDetail[i].minPower >= parseInt(this.servicePowerDetail[i].maxPower)) {
                    this.servicePowerDetail[i].maxPower = parseInt(this.servicePowerDetail[i].minPower) + 1
                }
            }
        },
        handlerDelDetailItem(index) {
            this.servicePowerDetail.splice(index, 1)
            for (let i = 1; i < this.servicePowerDetail.length; i++) {
                if (this.servicePowerDetail[i].minPower != parseInt(this.servicePowerDetail[i - 1].maxPower) + 1) {
                    this.servicePowerDetail[i].minPower = parseInt(this.servicePowerDetail[i - 1].maxPower) + 1
                }
                if (this.servicePowerDetail[i].minPower >= parseInt(this.servicePowerDetail[i].maxPower)) {
                    this.servicePowerDetail[i].maxPower = parseInt(this.servicePowerDetail[i].minPower) + 1
                }
            }
        },
        powerTypeChange () {
            this.servicePowerDetail = JSON.parse(JSON.stringify(this.servicePowerDetailInit))
            if(this.form.powerType == 0) {
                this.form.lowChoice = 240
                this.form.highChoice = 720
                this.form.stepChoice = 60
                this.form.defaultChoicePrice = 480
            }else if(this.form.powerType == 1) {
                this.servicePowerDetail.map((item,index) => {
                    if(index == 0) {
                        item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                    }else {
                        item.serviceBaseFee = parseInt((this.serviceInfo.serviceBaseFee * item.chargeScale / 100)*100)/100
                    }
                    item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                })
                this.form.lowChoice = 240
                this.form.highChoice = 720
                this.form.stepChoice = 60
                this.form.defaultChoicePrice = 480
            }else if(this.form.powerType == 2) {
                this.servicePowerDetail.map((item,index) => {
                    if(index == 0) {
                        item.serviceBaseTime = this.serviceInfo.serviceBaseTime
                    }else {
                        item.serviceBaseTime = (this.serviceInfo.serviceBaseTime / item.chargeScale * 100).toFixed()
                    }
                    item.serviceBaseFee = this.serviceInfo.serviceBaseFee
                })
                this.form.lowChoice = 1
                this.form.highChoice = 12
                this.form.stepChoice = 1
                this.form.defaultChoicePrice = 2
            }
        },
        serviceTypeChange () {
            this.form.selfPower = 0
        },
        chooseShowTypeChange () {
            if(this.form.chooseShowType == 1) {
                this.form.lowChoice = 1
                this.form.highChoice = 5
                this.form.stepChoice = 0.1
                this.form.defaultChoicePrice = 2
            }else if(this.form.chooseShowType == 2) {
                this.form.lowChoice = 240
                this.form.highChoice = 720
                this.form.stepChoice = 60
                this.form.defaultChoicePrice = 480
            }else if(this.form.chooseShowType == 3) {
                this.form.lowChoice = 1
                this.form.highChoice = 12
                this.form.stepChoice = 1
                this.form.defaultChoicePrice = 2
            }
        },
        handlerInputBaseChoice () {
            if(!Number(this.form.baseChoice)) {
                this.form.overChoice = ''
            }
        },
        handleSetDefaultPower (index) {
            this.servicePowerDetail = this.servicePowerDetail.map((item,idx)=>{
                return {
                    ...item,
                    isDefault: index == idx ? 1 : 0
                }
            })
        },
        // 获取定价详情
        getPlanInfo () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getPlanDetail,
                params: {
                    chargingPlanId: this.id
                }
            }).then(({
                data
            }) => {
                let baseAmount, 
                serviceBaseFee, 
                chargeBaseFee, 
                isService = 0, 
                serviceType = 0, 
                isServicePower = 0, 
                powerType = 1, 
                isOccupation, 
                chooseShowType = '', 
                defaultChoicePrice = data.defaultChoicePrice
                if(data.type == 5 || data.type == 10 || data.type == 11){
                    chargeBaseFee = data.chargeBaseFee?Number((data.chargeBaseFee/10).toFixed(5)):''
                }else{
                    chargeBaseFee = data.chargeBaseFee?Number((data.chargeBaseFee/100).toFixed(2)):''
                }
                if(data.type == 2 || data.type == 5 || data.type == 7 || data.type == 10 || data.type == 11 || data.type == 12) { //根据计费type，决定各计费模块开关状态
                    isService = 1
                    if(data.type == 2 || data.type == 7 || data.type == 12) {
                        serviceType = 1
                    }
                    if(data.type == 2) {
                        powerType = 0
                    }
                    if(data.type == 7) {
                        powerType = 1
                    }
                    if(data.type == 12) {
                        powerType = 2
                        defaultChoicePrice = data.defaultChoicePrice?Number((data.defaultChoicePrice/100).toFixed(2)):''
                    }
                    if(data.type == 5) {
                        serviceType = 2
                        chooseShowType = 2
                    }
                    if(data.type == 10) {
                        serviceType = 2
                        chooseShowType = 3
                        defaultChoicePrice = data.defaultChoicePrice?Number((data.defaultChoicePrice/100).toFixed(2)):''
                    }
                    if(data.type == 11) {
                        serviceType = 2
                        chooseShowType = 1
                    }
                }
                if(data.serviceBaseFee) {
                    serviceBaseFee = data.serviceBaseFee?Number((data.serviceBaseFee/100).toFixed(2)):''
                    isOccupation = 1
                }else {
                    isOccupation = 0
                }
                this.form = {
                    ...data,
                    baseAmount: data.baseAmount?Number((data.baseAmount/10).toFixed(5)):'',
                    stepAmount: data.stepAmount?Number((data.stepAmount/100).toFixed(2)):'',
                    serviceBaseFee: serviceBaseFee,
                    chargeBaseFee: chargeBaseFee,
                    managermentFee: data.managermentFee?Number((data.managermentFee/100).toFixed(2)):'',
                    serviceType: serviceType,
                    isService: isService,
                    isServicePower: isServicePower,
                    powerType: powerType,
                    isOccupation: isOccupation,
                    chooseShowType: chooseShowType,
                    defaultChoicePrice: defaultChoicePrice
                };
                if(data.type == 10 || data.type == 12) {
                    this.form.lowChoice = this.form.lowChoice?Number((this.form.lowChoice/100).toFixed(2)):''
                    this.form.highChoice = this.form.highChoice?Number((this.form.highChoice/100).toFixed(2)):''
                    this.form.stepChoice = this.form.stepChoice?Number((this.form.stepChoice/100).toFixed(2)):''
                }
                this.serviceInfo = {
                    serviceBaseFee: chargeBaseFee,
                    serviceBaseTime: data.chargeBaseTime,
                }//电费服务费信息
                this.occupationInfo = {
                    serviceBaseFee: serviceBaseFee,
                    serviceBaseTime: data.serviceBaseTime,
                    chargingDelayTime: data.chargingDelayTime,
                    rule: [],
                }//电费服务费信息
                console.log(this.form,111);
                this.getPlanSpecial();
                this.getServiceFeePower()
            })
        },
        // 获取服务费特殊时间段配置
        getPlanSpecial () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getPlanRule,
                params: {
                    chargingPlanId: this.id
                }
            }).then(({
                data
            }) => {
                let datas = data
                datas = datas.map(item=>{
                    return {
                        ...item,
                        serviceFee: item.serviceFee?Number((item.serviceFee/100).toFixed(2)):'0',
                        feeRuleType: JSON.stringify(item.feeRuleType)
                    }
                })
                this.occupationInfo.rule = datas
            
            })
        },
        // 获取功率服务费配置
        getServiceFeePower () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getPowerServiceDetail + '?chargingPlanId=' + this.id,
            }).then(({
                data
            }) => {
                if (data && data.length > 0) {
                    this.servicePowerDetail = data.map(item => {
                        return {
                            ...item,
                            serviceBaseFee: (item.price/100).toFixed(2),
                            serviceBaseTime: item.minute,
                            chargeScale: (item.price / this.serviceInfo.serviceBaseFee).toFixed(5)
                        }
                    });
                }
            })
        },
        submit () {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let reqData = {
                        id: this.id,
                        powerRule: this.form.powerType == 1 || this.form.powerType == 2 ? this.servicePowerDetail : [],
                        ...this.form,
                    }
                    if(this.form.isService && this.form.serviceType == 1) {
                        if(!this.serviceInfo.serviceBaseFee && this.serviceInfo.serviceBaseFee!==0 && this.serviceInfo.serviceBaseFee!=='0') {
                            this.$message.error("请输入基础电费服务费");
                            return;
                        }
                        if(!Number(this.serviceInfo.serviceBaseTime)) {
                            this.$message.error("请输入基础电费服务费计时时长");
                            return;
                        }
                        reqData = {
                            ...reqData,
                            chargeBaseFee: this.serviceInfo.serviceBaseFee,//电费服务费基础收费
                            chargeBaseTime: this.serviceInfo.serviceBaseTime,//电费服务费基础收费
                        }
                    }
                    if(this.form.serviceType == 2){
                        if(!Number(reqData.chargeBaseFee)) {
                            this.$message.error("请输入电费服务费的基础服务费");
                            return;
                        }
                        reqData.chargeBaseFee = this.$decimal.decimal_ride(reqData.chargeBaseFee, 10)
                        console.log(reqData.chargeBaseFee,'reqData.chargeBaseFee');
                        if(this.form.chooseShowType == 3) {
                            reqData.lowChoice = reqData.lowChoice?Number((reqData.lowChoice*100).toFixed(0)):0
                            reqData.highChoice = reqData.highChoice?Number((reqData.highChoice*100).toFixed(0)):0
                            reqData.stepChoice = reqData.stepChoice?Number((reqData.stepChoice*100).toFixed(0)):0
                            reqData.defaultChoicePrice = reqData.defaultChoicePrice?Number((reqData.defaultChoicePrice*100).toFixed(0)):0
                        }
                        if(this.form.chooseShowType == 2) {
                            reqData.baseMinutes = reqData.lowChoice
                        }
                        reqData.overChoice = reqData.overChoice?reqData.overChoice:0
                    }else{
                        reqData.chargeBaseFee = reqData.chargeBaseFee?Number((reqData.chargeBaseFee*100).toFixed(0)):0
                        if(this.form.powerType == 2) {
                            reqData.lowChoice = reqData.lowChoice?Number((reqData.lowChoice*100).toFixed(0)):0
                            reqData.highChoice = reqData.highChoice?Number((reqData.highChoice*100).toFixed(0)):0
                            reqData.stepChoice = reqData.stepChoice?Number((reqData.stepChoice*100).toFixed(0)):0
                            reqData.defaultChoicePrice = reqData.defaultChoicePrice?Number((reqData.defaultChoicePrice*100).toFixed(0)):0
                        }
                    }
                    if(this.form.isOccupation) { // 如果开启占位费
                        reqData = {
                            ...reqData,
                            ...this.occupationInfo,
                            serviceBaseFee: this.occupationInfo.serviceBaseFee?Number((this.occupationInfo.serviceBaseFee*100).toFixed(0)):0
                        }
                    }else {
                        reqData.serviceBaseFee = null
						reqData.serviceBaseTime = null
						reqData.chargingDelayTime = null
						reqData.rule = []
                    }
                    // reqData.baseAmount = reqData.baseAmount?parseFloat(reqData.baseAmount*100):0
                    reqData.baseAmount = this.$decimal.decimal_ride(reqData.baseAmount, 10)
                    reqData.stepAmount = reqData.stepAmount?Number((reqData.stepAmount*100).toFixed(0)):0
                    reqData.managermentFee = reqData.managermentFee?Number((reqData.managermentFee*100).toFixed(0)):0
                    let checkRulesResult = true
                    if(reqData.rule && reqData.rule.length) {
                        reqData.rule = reqData.rule.map(item=>{
                            return {
                                ...item,
                                serviceFee: item.serviceFee || item.serviceFee===0 || item.serviceFee==='0'?Number((item.serviceFee*100).toFixed(0)):''
                            }
                        })
                        checkRulesResult = await this.checkRules(reqData)
                    }

                    if (this.form.powerType == 1 || this.form.powerType == 2) {
                        for (let i = 0; i < reqData.powerRule.length; i++) {
                            let power = reqData.powerRule[i]
                            if (power.minPower === '' || power.maxPower === '' || power.serviceBaseFee === '') {
                                this.$message.error("请填写完整功率服务费信息");
                                return;
                            }
                        }
                    }

                    reqData.powerRule = reqData.powerRule.map(item=>{
                        return {
                            ...item,
                            serviceBaseFee: Number((item.serviceBaseFee*100).toFixed(0))
                        }
                    })
                    
                    if(!this.form.isService) {
                        reqData.type = 9 // 仅电量计费
                    }else if(this.form.isService) {
                        if(this.form.serviceType == 1) {
                            reqData.type = 2 // 电量 + 时长服务费
                            if(this.form.powerType == 1) { //电费功率服务费按时长计算
                                reqData.type = 7
                            }else if(this.form.powerType == 2){ //电费功率服务费按金额计算
                                reqData.type = 12
                            }
                        }else if(this.form.serviceType == 2) {
                            if(this.form.chooseShowType == 1) {
                                reqData.type = 11
                            }else if(this.form.chooseShowType == 2) {
                                reqData.type = 5 // 电量 + 电量服务费
                            }else if(this.form.chooseShowType == 3) {
                                reqData.type = 10
                            }
                        }
                    }
                    if(checkRulesResult){
                        let apiUrl = this.id?this.$Config.apiUrl.editPlan:this.$Config.apiUrl.createPlan
                        this.$confirm('是否确认提交？','温馨提示')
                        .then(_=>{
                            this.$Axios._post({
                                url: apiUrl,
                                method: "post",
                                params: reqData
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$router.back()
                                    this.$store.dispatch("delView", this.$route);
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        })
                        .catch(_=>{})
                    }
                }
            })
        },
        checkRules (reqData) {
            return new Promise((resolve, reject) => {
                if(reqData.rule.length && reqData.rule){
                    for (let index = 0; index < reqData.rule.length; index++) {
                        const item = reqData.rule[index];
                        if(!item.startTime){
                            this.$message.error('特殊时间段的开始时间不能为空')
                            resolve(false)
                            return
                        }
                        if(!item.endTime){
                            this.$message.error('特殊时间段的结束时间不能为空')
                            resolve(false)
                            return
                        }
                        if(!item.serviceFee && item.serviceFee !== 0  && item.serviceFee !== '0'){
                            this.$message.error('请输入特殊时间段的费用')
                            resolve(false)
                            return
                        }
                        if(!item.serviceMinutes){
                            this.$message.error('请输入特殊时间段的计时时长')
                            resolve(false)
                            return
                        }
                        if(!item.feeRuleType){
                            this.$message.error('请选择特殊时间段的生效类型')
                            resolve(false)
                            return
                        }
                    }
                    resolve(true)
                }else{
                    resolve(true)
                }
                
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
    },
};
</script>

<style lang="scss" scoped>
    .form-text{
        display: block;
        width: 40px;
        min-width: 40px;
        text-align: center;
    }
    .form-text1{
        display: block;
        // width: 60px;
        min-width: 60px;
        text-align: center;
    }
    .input-size{
        width: 120px;
        min-width: 120px;
    }
</style>