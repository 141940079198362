<template>
	<div style="width: 1000px;">

		<span class="a-fs-16 a-c-333 a-fw-b">基础定价</span>
		<el-row :gutter="20">
			<el-col :span="12">
				<le-input label="定价名称" v-model="price.name" notnull large :maxlength="30"></le-input>
			</el-col>
			<el-col :span="12">
				<le-input label="定价描述" v-model="price.description" notnull large :maxlength="50"></le-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="a-mt-24">
			<el-col :span="12">
				<div class="a-flex-rfsc">
					<div class="s-search-label a-flex-rfec " style="min-width: 82px;white-space: nowrap;">
						<span class="a-c-red a-fs-14">*</span>
						<div>起步费用</div>
					</div>
					<el-input type="number" class="a-ml-20 " style="width: 144px;" placeholder="输入起步费用"
                        @input="price.baseAmount = util.checkMoney(price.baseAmount)"
						v-model="price.baseAmount">
					</el-input>

					<span class="a-fs-14 a-c-666 a-ml-08">元</span>

					<el-input type="number" class="a-ml-30 " style="width: 144px;" placeholder="输入基本时长"
						v-model="price.baseMinutes">
					</el-input>
					<span class="a-fs-14 a-c-666 a-ml-08">分钟</span>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="a-flex-rfsc">
					<div class="s-search-label a-flex-rfec " style="min-width: 82px;white-space: nowrap;">
						<span class="a-c-red a-fs-14">*</span>
						<div>加时费用</div>
					</div>
					<el-input type="number" class="a-ml-20 " style="width: 144px;" placeholder="输入加时费用"
                        @input="price.stepAmount = util.checkMoney(price.stepAmount)"
						v-model="price.stepAmount">
					</el-input>

					<span class="a-fs-14 a-c-666 a-ml-08">元</span>

					<el-input type="number" class="a-ml-30 " style="width: 144px;" placeholder="输入加时时长"
						v-model="price.stepMinutes">
					</el-input>
					<span class="a-fs-14 a-c-666 a-ml-08">分钟</span>

				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="a-mt-24">
			<el-col :span="12">
				<le-input-num label="犹豫时长" v-model="price.hesitationMinutes" notnull large></le-input-num>
			</el-col>
			<el-col :span="12">
				<le-input-num label="最长时长" v-model="price.maxMinutes" notnull large></le-input-num>
			</el-col>
		</el-row>
		<div class="a-c-666 a-fs-14" style="margin-left: 112px;">· 犹豫时长：订单结束时不满该时间，则订单不计算费用</div>
        <el-row :gutter="20" class="a-mt-24">
			<el-col :span="12">
				<le-input-num :label="price.isPower==1 && price.powerType == 2 ? '默认开电金额' : '默认开电时长'" v-model="price.defaultChoicePrice" notnull large></le-input-num>
			</el-col>
		</el-row>
		<div class="a-mt-45 a-flex-rfsc">
			<span class="a-fs-16 a-c-333 a-fw-b">功率计费</span>
			<le-switch v-model="price.isPower" inacColor="#bbb"></le-switch>
		</div>
		<span class="a-mt-16 a-c-red a-fs-14">* 开启功率计费前，请先完成基础定价设置</span> 
        <div class="a-flex-rfsc a-mt-12" v-if="price.isPower==1">
            <el-radio-group v-model="price.powerType" @change='powerTypeChange'>
                <el-radio :label="1">计费功率</el-radio>
                <el-radio :label="2">计时功率</el-radio>
            </el-radio-group>
        </div>
        <div class="a-flex-rfsc" v-if="price.isPower==1 && price.powerType == 1">
            <span class="a-fs-14 a-c-333">自选功率</span>
            <le-switch v-model="price.selfPower" inacColor="#bbb"></le-switch>
        </div>
		<el-table :data="powerDetail" :highlight-current-row="true" class="a-mt-12" v-if="price.isPower==1">
            <el-table-column label="是否默认" v-if="price.selfPower" width='80'>
				<template slot-scope="scope">
					<el-radio v-model="scope.row.isDefault" :label="1" @input="handleSetDefaultPower(scope.$index)">&nbsp;</el-radio>
				</template>
			</el-table-column>

			<el-table-column label="功率范围（瓦）" width="300">
				<template slot-scope="scope">
					<div class="a-ptb-18 a-flex-rfsc">
						<el-input type="number" style="width: 96px;" v-model="scope.row.minPower"
							@input="e=>handlerMinPowerInput(e,scope.$index)"
							@blur="e=>handlerMinPowerBlur(e,scope.$index)">
						</el-input>

						<span class="a-fs-14 a-c-666 a-ml-12">-</span>

						<el-input type="number" class="a-ml-12 " style="width: 96px;" v-model="scope.row.maxPower"
							@input="e=>handlerMaxPowerInput(e,scope.$index)"
							@blur="e=>handlerMaxPowerBlur(e,scope.$index)">
						</el-input>
					</div>
				</template>
			</el-table-column>

			<el-table-column label="起步费用" >
				<template slot-scope="scope">
					<div class="a-ptb-18 a-flex-rfsc" v-if="price.powerType == 1">
						<el-input type="number" style="width: 96px;" v-model="scope.row.baseAmount"
							@input="e=>handlerScaleAmountInput(e,scope.$index)">
						</el-input>
						<span class="a-fs-14 a-c-666 a-ml-12">元</span> 
						<span class="a-fs-14 a-c-333 a-ml-12">{{scope.row.baseMinutes}}</span>
						<span class="a-fs-14 a-c-666 a-ml-12">分钟</span>
					</div>
                    <div class="a-ptb-18 a-flex-rfsc" v-if="price.powerType == 2">
                        <span class="a-fs-14 a-c-333">{{ scope.row.baseAmount }}</span>
						<span class="a-fs-14 a-c-666">元</span> 
                        <el-input type="number" style="width: 96px;margin-left:10px" v-model="scope.row.baseMinutes"
							@input="e=>handlerScaleMinutesInput(e,scope.$index)">
						</el-input>
						<span class="a-fs-14 a-c-666 a-ml-12">分钟</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="加时费用" >
				<template slot-scope="{row}">
					<div class="a-ptb-18 a-flex-rfsc">
						<span class="a-fs-14 a-c-333 ">每{{row.stepAmount}}元/{{row.stepMinutes}}分钟</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="scope">
					<div class="a-flex-rfsc">
						<div @click="handlerAddDetailItem(scope.$index)" class="a-cursor-p">
							<el-tooltip class="item" effect="dark" content="添加条目" placement="top">
								<img src="../../../assets/icon/option-add-circle.png" class="a-wh-16" />
							</el-tooltip>
						</div>
						<div @click="handlerDelDetailItem(scope.$index)" class="a-cursor-p a-ml-16"
							v-if="scope.$index>0">
							<el-tooltip class="item" effect="dark" content="删除条目" placement="top">
								<img src="../../../assets/icon/option-del-circle.png" class="a-wh-16" />
							</el-tooltip>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>

        <!-- 按金额浮动收费时，不可开启服务费及管理费 -->
        <div class="a-mt-45 a-flex-rfsc" > 
			<span class="a-fs-16 a-c-333 a-fw-b">占位服务费</span>
			<le-switch v-model="price.isService" inacColor="#bbb"></le-switch>
		</div>
        <le-price-service :form.sync="serviceInfo" :showSpecial="true" v-if="price.isService"></le-price-service>
        <div class="a-mt-45 a-flex-rfsc">
			<span class="a-fs-16 a-c-333 a-fw-b">管理费</span>
			<le-switch v-model="price.needManagermentFee" :active-value="1" :inactive-value="0" inacColor="#bbb"></le-switch>
		</div>
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="a-flex-rfsc" v-if="price.needManagermentFee">
                    <el-input v-model="price.managermentFee" maxlength="50" @input="price.managermentFee = util.checkMoney(price.managermentFee)" placeholder="请输入费用" class="input-size"></el-input>
                    <span class="form-text">元</span>
                    <el-select v-model="price.managermentMinutes" clearable placeholder="时长" class="input-size">
                        <el-option
                            v-for="(ite,idx) in managermentMinutesDic"
                            :key="idx"
                            :label="ite.label"
                            :value="ite.value">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
        <div class="a-mt-45 a-flex-rfsc" v-if="price.needManagermentFee || price.isService"> 
			<span class="a-fs-16 a-c-333 a-fw-b">占位服务费/管理费收费模式</span>
			<el-radio-group v-model="price.postServiceFee" class="a-ml-24">
                <el-radio :label="0">现付</el-radio>
                <el-radio :label="1">后付</el-radio>
            </el-radio-group>
		</div>
        <div style="height: 100px;"></div>
		<div class="a-line-t-e0 footerBox">
			<el-button class="a-ml-24 a-mt-15" type="primary" @click="handlerSumbit">确 定</el-button>
			<el-button class="a-ml-24 a-mt-15" @click="handlerCancel">取 消</el-button>
		</div>
	</div>

</template>
<script>
	import util from '../../../utils/util.js'
    import lePriceService from './le-price-service.vue';
	export default {
    components: { lePriceService },
		props: {
			priceId: {
				required: false,
			},
		},
		data() {
			return {
				util: util,
				price: {
					name: '',
					description: '',
					baseMinutes: 240,
					baseAmount: 1,
					stepMinutes: 60,
					stepAmount: 0.25,
					hesitationMinutes: 10,
					maxMinutes: 720,
					isPower: 0,
                    isService: 0,
                    managermentFee: '',
                    needManagermentFee: 0,
                    managermentMinutes: '',
                    powerType: 1,
                    selfPower: 0,
                    postServiceFee: 0,//服务费现付后付
                    defaultChoicePrice: 480,//充电快捷选项中默认选择项, 计时功率时为金额
				},
				powerDetail: [],
				powerDetailInit: [{
						minPower: 0,
						maxPower: 200,
						chargeScale: 100,
						baseAmount: 1.00,
                        baseMinutes: 0,
                        stepAmount: 0,
                        stepMinutes: 0,
                        isDefault: 0
					},
					{
						minPower: 201,
						maxPower: 400,
						chargeScale: 125,
						baseAmount: 1.25,
                        baseMinutes: 0,
                        stepAmount: 0,
                        stepMinutes: 0,
                        isDefault: 1
					},
					{
						minPower: 401,
						maxPower: 600,
						chargeScale: 150,
						baseAmount: 1.50,
                        baseMinutes: 0,
                        stepAmount: 0,
                        stepMinutes: 0,
                        isDefault: 0
					},
					{
						minPower: 601,
						maxPower: 800,
						chargeScale: 175,
						baseAmount: 1.75,
                        baseMinutes: 0,
                        stepAmount: 0,
                        stepMinutes: 0,
                        isDefault: 0
					},
					{
						minPower: 801,
						maxPower: 1200,
						chargeScale: 200,
						baseAmount: 2.00,
                        baseMinutes: 0,
                        stepAmount: 0,
                        stepMinutes: 0,
                        isDefault: 0
					}
				],
                serviceInfo: {
                    serviceBaseFee: '',
                    serviceBaseTime: '',
                    chargingDelayTime: '',
                    rule: [],
                },//服务费信息
				stationSize: 0,
				id: '',
                managermentMinutesDic: [{
                    label: '1分钟',
                    value: 1
                },{
                    label: '5分钟',
                    value: 5
                },{
                    label: '10分钟',
                    value: 10
                },{
                    label: '15分钟',
                    value: 15
                },{
                    label: '20分钟',
                    value: 20
                },{
                    label: '30分钟',
                    value: 30
                }]
			};
		},
		watch: {
			priceId: {
				handler(val) {
					if (val) {
						this.id = val;
						this.getPlanDetail();
					}
				},
				immediate: true
			},
			'price.isPower': {
				handler(val) {
                    if(val == 0) {
                        this.price.powerType = 1 //关闭功率计费时，重置功率计费模式为按时长功率
                        this.price.selfPower = 0 //关闭功率计费时，关闭自选功率
                    }
                    this.powerDetail = JSON.parse(JSON.stringify(this.powerDetailInit))
                    if(this.price.powerType == 1) {
                        this.powerDetail.map(item => {
                            // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                            item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                            item.baseMinutes = this.price.baseMinutes
                            // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                            item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                            item.stepMinutes = this.price.stepMinutes
                        })
                    }else if(this.price.powerType == 2) {
                        this.powerDetail.map(item => {
                            item.baseAmount = this.price.baseAmount
                            item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                            item.stepAmount = this.price.stepAmount
                            item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                        })
                    }
					this.handlerOpenPower()
				},
			},
            'price.isService': {
                handler(val) {
                    if(!val) {
                        this.serviceInfo = {
                            serviceBaseFee: '',
                            serviceBaseTime: '',
                            chargingDelayTime: '',
                            rule: [],
                        }
                    }
				},
            },
			'price.baseAmount': {
				handler(val) {
					if (val) {
						if(val<0){
							this.price.baseAmount =0
						}
						if(this.price.powerType == 1) {
                            this.powerDetail.map(item => {
                                // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                                item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                                item.baseMinutes = this.price.baseMinutes
                                // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                                item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                                item.stepMinutes = this.price.stepMinutes
                            })
                        }else if(this.price.powerType == 2) {
                            this.powerDetail.map(item => {
                                item.baseAmount = this.price.baseAmount
                                item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                                item.stepAmount = this.price.stepAmount
                                item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                            })
                        }
					}

				},
			},
			'price.stepAmount': {
				handler(val) {
                    if(val) {
                        if(val<0){
                            this.price.stepAmount =0
                        }
                        if(this.price.powerType == 1) {
                            this.powerDetail.map(item => {
                                // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                                item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                                item.baseMinutes = this.price.baseMinutes
                                // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                                item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                                item.stepMinutes = this.price.stepMinutes
                            })
                        }else if(this.price.powerType == 2) {
                            this.powerDetail.map(item => {
                                item.baseAmount = this.price.baseAmount
                                item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                                item.stepAmount = this.price.stepAmount
                                item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                            })
                        }
                    }
				},
			},
            'price.baseMinutes': {
                handler (val) {
                    if(val) {
                        if(this.price.powerType == 1) {
                            this.powerDetail.map(item => {
                                // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                                item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                                item.baseMinutes = this.price.baseMinutes
                                // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                                item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                                item.stepMinutes = this.price.stepMinutes
                            })
                        }else if(this.price.powerType == 2) {
                            this.powerDetail.map(item => {
                                item.baseAmount = this.price.baseAmount
                                item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                                item.stepAmount = this.price.stepAmount
                                item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                            })
                        }
                    }
                }
            },
            'price.stepMinutes': {
                handler (val) {
                    if(val) {
                        if(this.price.powerType == 1) {
                            this.powerDetail.map(item => {
                                // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                                item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                                item.baseMinutes = this.price.baseMinutes
                                // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                                item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                                item.stepMinutes = this.price.stepMinutes
                            })
                        }else if(this.price.powerType == 2) {
                            this.powerDetail.map(item => {
                                item.baseAmount = this.price.baseAmount
                                item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                                item.stepAmount = this.price.stepAmount
                                item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                            })
                        }
                    }
                }
            },
			powerDetail:{
				handler(val){
					if(val){
						val.map(item=>{
							if(item.baseAmount<0){
								item.baseAmount = 0
								item.chargeScale = 0
							}
						})
					}
				},
				deep:true
			}

		},
		mounted() {
			// this.powerDetail = JSON.parse(JSON.stringify(this.powerDetailInit))
		},

		methods: {
			/**
			 * 获取定价详情
			 */
			getPlanDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanDetail,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
                    let isPower = 0, isService = 0, powerType = 1, defaultChoicePrice = data.defaultChoicePrice
                    if(data.type == 1 || data.type == 4 || data.type == 6 || data.type == 8) {
                        isPower = 1
                        if(data.type == 1 || data.type == 4) {
                            powerType = 1
                        }else {
                            powerType = 2
                            defaultChoicePrice = Number((data.defaultChoicePrice / 100).toFixed(2)) //充电快捷选项中默认选择项, 计时功率时为金额
                        }
                    }else {
                        isPower = 0
                    }
                    if(data.type == 3 || data.type == 4 || data.type == 8) {
                        isService = 1
                    }else {
                        isService = 0
                    }

					this.price = {
						...data,
						baseAmount: data.baseAmount / 100,
						stepAmount: data.stepAmount / 100,
                        managermentFee: Number((data.managermentFee / 100).toFixed(2)),
						isPower: isPower,
                        isService: isService,
                        powerType: powerType,
                        defaultChoicePrice: defaultChoicePrice,// 充电快捷选项中默认选择项
					};
                    this.serviceInfo = {
                        serviceBaseFee: Number((data.serviceBaseFee / 100).toFixed(2)),
                        serviceBaseTime: data.serviceBaseTime,
                        chargingDelayTime: data.chargingDelayTime,
                        rule: []
                    }
                    this.getPlanSpecial()
					this.getPlanPower();
				})
			},
			/**
			 * 获取功率计费详情
			 */
			getPlanPower() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getPlanPower,
					params: {
						chargingPlanId: this.priceId
					}
				}).then(({
					data
				}) => {
					if (data && data.length > 0) {
                        this.powerDetail = data.map(item => {
                            return {
                                ...item,
                                baseAmount: (item.price/100).toFixed(2),
								baseMinutes: item.minute,
								stepAmount: (item.stepPrice/100).toFixed(2),
								stepMinutes: item.stepMinute
                            }
                        });
						
					}else{
                        this.price.isPower = 0
                        this.powerDetail = []
                    }

				})
			},

            // 获取服务费计费详情
            getPlanSpecial () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getPlanRule,
                    params: {
                        chargingPlanId: this.priceId
                    }
                }).then(({
                    data
                }) => {
                    let datas = data
                    datas = datas.map(item=>{
                        return {
                            ...item,
                            serviceFee: item.serviceFee?Number((item.serviceFee/100).toFixed(2)):'0',
                            feeRuleType: JSON.stringify(item.feeRuleType)
                        }
                    })
                    this.serviceInfo.rule = datas
                
                })
            },

			handlerMinPowerInput(e, index) {
				if (index > 0 && e !== '') {
					this.powerDetail[index - 1].maxPower = parseInt(e) - 1
				}
			},
			handlerMinPowerBlur(e, index) {
				if (index > 0 && e.target.value !== '') {
					this.powerDetail[index - 1].maxPower = parseInt(e.target.value) - 1
					if (this.powerDetail[index - 1].maxPower - this.powerDetail[index - 1].minPower <= 0) {
						this.powerDetail.splice(index - 1, 1)
					}
				}
			},
			handlerMaxPowerInput(e, index) {
				if (index != this.powerDetail.length - 1 && e !== '') {
					this.powerDetail[index + 1].minPower = parseInt(e) + 1

				}
			},
			handlerMaxPowerBlur(e, index) {
				if (index != this.powerDetail.length - 1 && e.target.value !== '') {
					this.powerDetail[index + 1].minPower = parseInt(e.target.value) + 1
					if (this.powerDetail[index + 1].maxPower - this.powerDetail[index + 1].minPower <= 0) {
						this.powerDetail.splice(index + 1, 1)
					}
				}
			},


			handlerBaseAmountInput() {
				let amount = e.detail.value;
				if ((amount + '').match(/.*\.\d{3,10}/)) {
					this.$message.error("价格只能精确到分")
					// this.price.baseAmount = parseFloat(amount).toFixed(2)
                    this.price.baseAmount = util.checkMoney(this.price.baseAmount)
				}
			},
			handlerStepAmountInput() {
				let amount = e.detail.value;
				if ((amount + '').match(/.*\.\d{3,10}/)) {
					this.$message.error("价格只能精确到分")
					// this.price.stepAmount = parseFloat(amount).toFixed(2)
                    this.price.stepAmount = util.checkMoney(this.price.stepAmount)
				}
			},
			handlerAddDetailItem(index) {
				let lastDetail = this.powerDetail[index]
                if(this.price.powerType == 1) {
                    this.powerDetail.splice(index + 1, 0, {
                        minPower: lastDetail.maxPower + 1,
                        maxPower: lastDetail.maxPower + 200,
                        chargeScale: Number(lastDetail.chargeScale) + 25,
                        baseMinutes: this.price.baseMinutes,
                        baseAmount: ((Number(lastDetail.chargeScale) + 25) * this.price.baseAmount / 100).toFixed(2),
                        stepAmount: ((Number(lastDetail.chargeScale) + 25) * this.price.stepAmount / 100).toFixed(2),
                        stepMinutes: this.price.stepMinutes
                    })
                }else {
                    this.powerDetail.splice(index + 1, 0, {
                        minPower: lastDetail.maxPower + 1,
                        maxPower: lastDetail.maxPower + 200,
                        chargeScale: Number(lastDetail.chargeScale) + 25,
                        baseMinutes: (this.price.baseMinutes / (Number(lastDetail.chargeScale) + 25) * 100).toFixed(),
                        baseAmount: this.price.baseAmount,
                        stepMinutes: (this.price.stepMinutes / (Number(lastDetail.chargeScale) + 25) * 100).toFixed(),
                        stepAmount: this.price.stepAmount,
                    })
                }
				

				for (let i = 1; i < this.powerDetail.length; i++) {
					if (this.powerDetail[i].minPower != parseInt(this.powerDetail[i - 1].maxPower) + 1) {
						this.powerDetail[i].minPower = parseInt(this.powerDetail[i - 1].maxPower) + 1
					}
					if (this.powerDetail[i].minPower >= parseInt(this.powerDetail[i].maxPower)) {
						this.powerDetail[i].maxPower = parseInt(this.powerDetail[i].minPower) + 1
					}
				}

			},
			handlerDelDetailItem(index) {
				this.powerDetail.splice(index, 1)
				for (let i = 1; i < this.powerDetail.length; i++) {
					if (this.powerDetail[i].minPower != parseInt(this.powerDetail[i - 1].maxPower) + 1) {
						this.powerDetail[i].minPower = parseInt(this.powerDetail[i - 1].maxPower) + 1
					}
					if (this.powerDetail[i].minPower >= parseInt(this.powerDetail[i].maxPower)) {
						this.powerDetail[i].maxPower = parseInt(this.powerDetail[i].minPower) + 1
					}
				}
			},
			handlerScaleAmountInput(e, index) {
				let amount = e;
				if ((amount + '').match(/.*\.\d{3,10}/)) {
					this.$message.error("价格只能精确到分")
					// this.powerDetail[index].baseAmount = parseFloat(amount).toFixed(2)
                    this.powerDetail[index].baseAmount = util.checkMoney(this.powerDetail[index].baseAmount)
				}
				if (amount) {
					this.powerDetail[index].chargeScale = (this.powerDetail[index].baseAmount / this.price.baseAmount * 100).toFixed(5)
                    if(this.price.powerType == 1) {
                        this.powerDetail.map(item => {
                            // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                            item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                            item.stepMinutes = this.price.stepMinutes
                        })
                    }else if(this.price.powerType == 2) {
                        this.powerDetail.map(item => {
                            item.stepAmount = this.price.stepAmount
                            item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                        })
                    }
				}

			},
            handlerScaleMinutesInput(e, index){
				let minutes  = e;
				if(minutes && !(minutes+'').match(/\d{1,10}/)){
					this.$msg("时长只能是整数")
					this.powerDetail[index].baseMinutes = parseFloat(minutes).toFixed()
					return;
				}
				if(minutes){
					this.powerDetail[index].chargeScale = (this.price.baseMinutes/minutes*100).toFixed(5)
                    if(this.price.powerType == 1) {
                        this.powerDetail.map(item => {
                            // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                            item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                            item.stepMinutes = this.price.stepMinutes
                        })
                    }else if(this.price.powerType == 2) {
                        this.powerDetail.map(item => {
                            item.stepAmount = this.price.stepAmount
                            item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                        })
                    }
				}
			},
			handlerOpenPower() {
				if (this.price.isPower == 1) {
					if (!this.price.baseAmount || !this.price.baseMinutes ||
						this.price.stepAmount === '' || this.price.stepMinutes === '') {
						this.$message.error("请先完成基础定价")
						this.price.isPower = 0
						return;
					}
				}else {
                    this.price.selfPower = 0
                }
			},
            powerTypeChange () {
                this.powerDetail = JSON.parse(JSON.stringify(this.powerDetailInit))
                if(this.price.powerType == 1) {
                    this.powerDetail.map(item => {
                        // item.baseAmount = parseInt((this.price.baseAmount * item.chargeScale / 100)*100)/100
                        item.baseAmount = (this.price.baseAmount * item.chargeScale / 100).toFixed(2)
                        item.baseMinutes = this.price.baseMinutes
                        // item.stepAmount = parseInt((this.price.stepAmount * item.chargeScale / 100)*100)/100
                        item.stepAmount = (this.price.stepAmount * item.chargeScale / 100).toFixed(2)
                        item.stepMinutes = this.price.stepMinutes
                    })
                    this.price.defaultChoicePrice = 480
                }else if(this.price.powerType == 2) {
                    this.powerDetail.map(item => {
                        item.baseAmount = this.price.baseAmount
                        item.baseMinutes = (this.price.baseMinutes / item.chargeScale * 100).toFixed()
                        item.stepAmount = this.price.stepAmount
                        item.stepMinutes = (this.price.stepMinutes / item.chargeScale * 100).toFixed()
                    })
                    this.price.isService = 0
                    this.price.needManagermentFee = 0
                    this.price.selfPower = 0
                    this.price.defaultChoicePrice = 2
                }
            },

            handleSetDefaultPower (index) {
                this.powerDetail = this.powerDetail.map((item,idx)=>{
                    return {
                        ...item,
                        isDefault: index == idx ? 1 : 0
                    }
                })
            },

            checkRules (reqData) {
                return new Promise((resolve, reject) => {
                    if(reqData.rule.length && reqData.rule){
                        for (let index = 0; index < reqData.rule.length; index++) {
                            const item = reqData.rule[index];
                            if(!item.startTime){
                                this.$message.error('特殊时间段的开始时间不能为空')
                                resolve(false)
                                return
                            }
                            if(!item.endTime){
                                this.$message.error('特殊时间段的结束时间不能为空')
                                resolve(false)
                                return
                            }
                            if(!item.serviceFee && item.serviceFee !== 0  && item.serviceFee !== '0'){
                                this.$message.error('请输入特殊时间段的费用')
                                resolve(false)
                                return
                            }
                            if(!item.serviceMinutes){
                                this.$message.error('请输入特殊时间段的计时时长')
                                resolve(false)
                                return
                            }
                            if(!item.feeRuleType){
                                this.$message.error('请选择特殊时间段的生效类型')
                                resolve(false)
                                return
                            }
                        }
                        resolve(true)
                    }else{
                        resolve(true)
                    }
                    
                })
            },

			async handlerSumbit() {
				if (!this.price.name) {
					this.$message.error("名称不能为空")
					return;
				}

				if (!this.price.description) {
					this.$message.error("描述不能为空");
					return;
				}

				if (!this.price.baseAmount) {
					this.$message.error("基本费用不能为空");
					return;
				}

				if ((this.price.baseAmount + '').match(/.*\.\d{3,10}/)) {
					this.$message.error("基本费用只能精确到分")
					return;
				}
				if (!this.price.baseMinutes) {
					this.$message.error("基本时长不能为空");
					return;
				}
				if (!(this.price.baseMinutes + '').match(/\d+/)) {
					this.$message.error("基本时长只能是整数")
					return;
				}
				if (!this.price.hesitationMinutes) {
					this.$message.error("犹豫时长不能为空");
					return;
				}
				if (!(this.price.hesitationMinutes + '').match(/\d+/)) {
					this.$message.error("犹豫时长只能是整数")
					return;
				}
				if (!this.price.stepMinutes) {
					this.$message.error("加时步长不能为空");
					return;
				}
				if (!(this.price.stepMinutes + '').match(/\d+/)) {
					this.$message.error("加时步长只能是整数")
					return;
				}
				if (!this.price.stepAmount) {
					this.$message.error("加时费用不能为空");
					return;
				}
				if ((this.price.stepAmount + '').match(/.*\.\d{3,10}/)) {
					this.$message.error("加时费用只能精确到分")
					return;
				}
				if (!this.price.maxMinutes) {
					this.$message.error("最长时长不能为空");
					return;
				}
				if (!(this.price.maxMinutes + '').match(/\d+/)) {
					this.$message.error("最长时长只能是整数")
					return;
				}
				if (this.price.isPower == 1 && this.powerDetail.length == 0) {
					this.$message.error("开启功率计费模式后,功率计费信息不能为空");
					return;
				}

				if (this.price.isPower == 1) {
					for (let i = 0; i < this.powerDetail.length; i++) {
						let power = this.powerDetail[i]
						if (power.minPower === '' || power.maxPower === '' || power.baseAmount === '') {
							this.$message.error("请填写完整功率计费信息");
							return;
						}
					}
				}
                if(this.price.isService) {
                    // 开启服务费
                    if(!this.serviceInfo.serviceBaseFee && this.serviceInfo.serviceBaseFee!==0 && this.serviceInfo.serviceBaseFee!=='0') {
                        this.$message.error("请输入基础占位服务费");
                        return;
                    }
                    if(!Number(this.serviceInfo.serviceBaseTime)) {
                        this.$message.error("请输入基础占位服务费计时时长");
                        return;
                    }
                }
                let checkRulesResult = await this.checkRules(this.serviceInfo)
                if(!checkRulesResult) {
                    return false
                }
                
				let param = {
					...this.price,
					baseAmount: this.price.baseAmount * 100,
					stepAmount: this.price.stepAmount * 100,
                    managermentFee: this.price.managermentFee?Number((this.price.managermentFee*100).toFixed(0)):0
				}
                let serviceParams = {
                    ...this.serviceInfo,
                    serviceBaseFee: this.serviceInfo?Number((this.serviceInfo.serviceBaseFee*100).toFixed(0)):0,
                }
                serviceParams.rule = serviceParams.rule.map(item=>{
                    return {
                        ...item,
                        serviceFee: item.serviceFee || item.serviceFee===0 || item.serviceFee==='0'?Number((item.serviceFee*100).toFixed(0)):''
                    }
                })
				if (this.price.isPower == 1) {
					param.power = this.powerDetail.map(item=>{
                        return {
                            ...item,
                            baseAmount: Number((item.baseAmount*100).toFixed(0)),
                            stepAmount: Number((item.stepAmount*100).toFixed(0)),
                        }
                    })
				}
                let type, defaultChoicePrice = this.price.defaultChoicePrice
                if(!this.price.isPower && !this.price.isService) {
                    type = 0 // 基础定价
                }else if(this.price.isPower && !this.price.isService) {
                    if(this.price.powerType == 1) {
                        type = 1 // 功率定价
                    }else if(this.price.powerType == 2) {
                        type = 6 // 时长+按金额功率
                        defaultChoicePrice = Number((defaultChoicePrice*100).toFixed(0)) //充电快捷选项中默认选择项, 计时功率时为金额
                    }
                }else if(!this.price.isPower && this.price.isService) {
                    type = 3 // 服务费时长定价
                }else if(this.price.isPower && this.price.isService) {
                    if(this.price.powerType == 1) {
                        type = 4 // 功率+服务费时长定价
                    }else if(this.price.powerType == 2) {
                        type = 8 // 按金额功率+服务费时长定价
                        defaultChoicePrice = Number((defaultChoicePrice*100).toFixed(0)) //充电快捷选项中默认选择项, 计时功率时为金额
                    }
                }
				if (this.priceId) {
					this.$Axios._post({
						url: this.$Config.apiUrl.editPlan,
						params: {
							...param,
                            ...serviceParams,
							id: this.priceId,
                            type: type,
                            defaultChoicePrice: defaultChoicePrice
						}
					}).then(({
						data
					}) => {
						this.$message.success("修改定价成功");
						this.$emit('submit', this.priceId)
					})
				} else {
					this.$Axios._post({
						url: this.$Config.apiUrl.createPlan,
						params: {
                            ...param,
                            ...serviceParams,
                            type: type
                        }
					}).then(({
						data
					}) => {
						this.$message.success("新增定价成功");
						this.id = data
						this.$emit('submit', this.id)
					})
				}


			},

			handlerCancel() {
				this.$emit('cancel')
			}
		},
	};
</script>

<style scoped>
    .form-text{
        display: block;
        width: 40px;
        min-width: 40px;
        text-align: center;
    }
</style>
