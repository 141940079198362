<template>
    <div>
        <el-form :model="form">
            
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-form-item label="基础服务费" prop="serviceBaseFee" >
                        <div class="a-flex-rfsc">
                            <el-input v-model="form.serviceBaseFee" maxlength="50" @input="form.serviceBaseFee = util.checkMoney(form.serviceBaseFee)" placeholder="请输入费用" class="input-size"></el-input>
                            <span class="form-text">元</span>
                            <el-input v-model="form.serviceBaseTime" maxlength="6" placeholder="请输入时长" class="input-size"></el-input>
                            <span class="form-text">分钟</span>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="showSpecial">
                <el-col :span="10">
                    <el-form-item label="充电延长时长" prop="chargingDelayTime" >
                        <div class="a-flex-rfsc">
                            <el-input v-model="form.chargingDelayTime" maxlength="6" placeholder="请输入时长" class="input-size"></el-input>
                            <span class="form-text">分钟</span>
                            <el-popover
                                placement="bottom-start"
                                title=""
                                width="400"
                                trigger="hover">
                                <div class="a-flex-cfsfs">
                                    计算时间延迟时间：指的是不收取充电费用，实际也没有开电。
                                    但是按照充电时长来进行规则处理。
                                    一般用于充电结束后xx时间内不收取费用或配合其他规则使用。
                                </div>
                                <span slot="reference" class="el-icon-info a-c-333" style="font-size: 16px;"></span>
                            </el-popover>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24" v-if="showSpecial">
                <el-col :span="24">
                    <el-form-item label="特殊时间段定价" >
                        <el-button type="primary" @click="addServerItem">添加时间段</el-button>
                        <div class="a-flex-rfsc a-mt-12" v-for="(item,index) in form.rule" :key="index">
                            <!-- :disabled="Number(item.endTime.split(':')[0]) <= Number(ite.split(':')[0]) && item.endTime != ''" -->
                            <el-select v-model="item.startTime" clearable placeholder="开始时间" class="input-size">
                                <el-option
                                    v-for="(ite,idx) in options"
                                    :key="idx"
                                    :label="ite"
                                    :value="ite">
                                </el-option>
                            </el-select>
                            <span class="form-text">至</span>
                            <!-- :disabled="Number(ite.split(':')[0]) <= Number(item.startTime.split(':')[0]) && item.startTime != ''" -->
                            <el-select v-model="item.endTime" clearable placeholder="结束时间" class="input-size">
                                <el-option
                                    v-for="(ite,idx) in options"
                                    :key="idx"
                                    :label="ite"
                                    :value="ite">
                                </el-option>
                            </el-select>
                            <span class="form-text1">，费用</span>
                            <el-input v-model="item.serviceFee" @input="item.serviceFee = util.checkMoney(item.serviceFee)" style="min-width: 120px;width: 120px;" maxlength="50"  placeholder="请输入费用"></el-input>
                            <span class="form-text">元，</span>
                            <!-- <el-input v-model="item.serviceMinutes" style="min-width: 120px;width: 120px;" maxlength="50"  placeholder="请输入时长"></el-input> -->
                            <span class="form-text1">{{ item.serviceMinutes }}分钟，</span>
                            <el-select v-model="item.feeRuleType" clearable placeholder="生效条件" class="input-size" style="width:160px">
                                <el-option
                                    v-for="(ite) in feeRuleTypeDic"
                                    :key="ite.value"
                                    :label="ite.label"
                                    :value="ite.value">
                                </el-option>
                            </el-select>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
								<img src="../../../assets/icon/option-del-circle.png" @click="deleteServiceItem(index)" class="a-wh-16 a-mlr-12" />
							</el-tooltip>
                            <el-tooltip class="item" effect="dark" content="添加" placement="top">
								<img src="../../../assets/icon/option-add-circle.png" @click="addServerItem" class="a-wh-16 a-mlr-12" />
							</el-tooltip>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="showSpecial">
                <el-col :span="20">
                    <el-form-item label="说明" >
                        <div class="a-flex-cfsfs a-fs-12">
                            <span style="color: #F56C6C">特殊时间段不能有重叠！！！</span>
                            定义基础服务费：基础服务费是用户使用服务时的基本费用，按照小时进行收取。不足一小时的使用时间也按照一小时收取。<br />
                            特殊时段定义：你可以定义特殊时段，这些时段可以有不同的收费规则和优先级。<br />
                            每个特殊时段都有三种生效条件可以选择：没有充电订单时生效、无条件生效、有充电订单时生效。<br />
                            优化定价逻辑：可以按照以下方式进行定价：<br />
                            如果没有特殊时段，直接按照基础服务费收取费用。<br />
                            如果存在特殊时段，按照特殊时段的优先级进行判断：<br />
                            如果特殊时段选择为"有充电订单时生效"，并且存在充电订单，则按照特殊时段的收费规则进行收费；如果没有充电订单，则按照基础服务费进行收费<br />
                            如果特殊时段选择为"无条件生效"，则按照特殊时段的收费规则进行收费，不管是否有充电订单。<br />
                            如果特殊时段选择为"没有充电时生效"，并且存在充电订单，则按照基础服务费进行收费；如果没有充电订单，则按照特殊时段的收费规则进行收费。<br />
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import util from '@/utils/util'
export default {
    data() {
        return {
            util: util,
            options: [],
            feeRuleTypeDic: [],
        };
    },
    props: {
        form: {
            default: ()=>{
                return {}
            }
        },
        showSpecial: {
            default: false,
            type: Boolean
        }
    },
    watch: {
        'form.serviceBaseTime':{
            handler (val) {
                this.form.rule.map(item=>{
                    item.serviceMinutes = val
                })
            }
        },
        form: {
            deep:true,
            handler (val) {
                this.$emit('update:form', val);
            }
        }
    },
    mounted() {
        this.options = []
        for (let index = 0; index < 24; index++) {
            if(index<10){
                this.options.push('0' + index + ':00')
            }else{
                this.options.push(index + ':00')
            }
        }
        this.$getDic('feeRuleType','select').then(res=>{ this.feeRuleTypeDic = res; })
    },

    methods: {
        addServerItem () {
            this.form.rule.push({
                startTime: '',
                endTime: '',
                serviceFee: '',
                serviceMinutes: this.form.serviceBaseTime,
                feeRuleType: ''
            })
        },
        deleteServiceItem (index) {
            this.form.rule.splice(index,1)
        },
        getPlanSpecial () {
            this.$Axios._get({
                url: this.$Config.apiUrl.getPlanRule,
                params: {
                    chargingPlanId: this.id
                }
            }).then(({
                data
            }) => {
                let datas = data
                datas = datas.map(item=>{
                    return {
                        ...item,
                        serviceFee: item.serviceFee?Number((item.serviceFee/100).toFixed(2)):'0',
                        feeRuleType: JSON.stringify(item.feeRuleType)
                    }
                })
                this.form.rule = datas
            
            })
        },
    },
};
</script>

<style lang="scss" scoped>
    .form-text{
        display: block;
        width: 40px;
        min-width: 40px;
        text-align: center;
    }
    .form-text1{
        display: block;
        // width: 60px;
        min-width: 60px;
        text-align: center;
    }
    .input-size{
        width: 120px;
        min-width: 120px;
    }
</style>